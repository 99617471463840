<template>
  <AdminPage contentComponent="AppAdminEventUploadCsv" />
</template>

<script>
import AdminPage from '@/templates/admin/AppAdminPage'

export default {
  name: 'TheEventUploadCsv',
  components: {
    AdminPage,
  },
}
</script>
